import { setCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';

export async function getGoogleAnalyticsSessionId(): Promise<string | null> {
    return getGoogleAnalyticsValue('session_id');
}

export async function getGoogleAnalyticsClientId(): Promise<string | null> {
    return getGoogleAnalyticsValue('client_id');
}

export async function getValueFromGtag(
    googleTrackingId: string,
    value: string
): Promise<string | null> {
    const gtagPromise = await new Promise((resolve) => {
        const timeout = setTimeout(() => {
            resolve(null);
        }, 2000);

        window.gtag('get', googleTrackingId, value, (id) => {
            clearTimeout(timeout);
            resolve(id);
        });
    });

    return (await gtagPromise) as string;
}

export async function getGoogleAnalyticsValue(value: string): Promise<string | null> {
    const googleTrackingId: string | undefined = document.documentElement.dataset.gaTrackingId;

    if (!window.gtag || !googleTrackingId) {
        return Promise.resolve(null);
    }

    const returnValue = await getValueFromGtag(googleTrackingId, value);

    if (typeof returnValue !== 'string') {
        return Promise.resolve(null);
    }

    setCookieValue(`ga_${value}`, returnValue);

    return returnValue;
}
