import { getGoogleAnalyticsClientId, getGoogleAnalyticsSessionId } from '../../util/google';

class GoogleAnalyticsIdManager {
    public static UNKNOWN_VALUE = 'unknown';

    public async initialize(): Promise<void> {
        await getGoogleAnalyticsClientId();
        await getGoogleAnalyticsSessionId();
    }
}

export { GoogleAnalyticsIdManager };
export default new GoogleAnalyticsIdManager();
