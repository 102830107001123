import { getCookieValue, setCookieValue } from '@naturehouse/nh-essentials/lib/cookies/cookie';
import ErrorBase from '@naturehouse/nh-essentials/lib/exceptions/ErrorBase';
import BigQuery from './BigQuery';
import ConvertSpTestTransformer from './ConvertSpTestTransformer';

export enum ConvertReporterEvent {
    EVENT_NAME = 'experience_impression',
    VARIANT_KEY = 'exp_variant_string',
    EXPERIMENT_KEY = 'exp_experiment_string'
}

type ConvertExperimentData = {
    [key: string]: {
        v: number;
        g: {
            [key: string]: number;
        };
    };
};

class ConvertReporter {
    readonly #cookie_name = '_conv_v';

    readonly #exp_key = '*exp:';

    public async track(): Promise<void> {
        const data = this.decodeCookie();
        const entries = Object.entries(data) || [];

        const promises = entries.map(async ([experimentId, variant]): Promise<void> => {
            await BigQuery.track({
                eventName: ConvertReporterEvent.EVENT_NAME,
                eventParams: [
                    {
                        eventKey: ConvertReporterEvent.VARIANT_KEY,
                        eventValue: String(variant.v)
                    },
                    {
                        eventKey: ConvertReporterEvent.EXPERIMENT_KEY,
                        eventValue: experimentId
                    }
                ]
            });
        });

        await Promise.all(promises);
    }

    public decodeCookie(): ConvertExperimentData {
        const cookieValue = this.#getCookieValue();

        if (!cookieValue) {
            return {};
        }

        const content = decodeURIComponent(cookieValue);

        if (content.indexOf(this.#exp_key) === -1) {
            return {};
        }

        const value = this.#processContent(content);

        try {
            return JSON.parse(value) ?? {};
        } catch (error) {
            setCookieValue('_conv_v', cookieValue, { 'max-age': -99999999 });
            throw new ErrorBase('Can not json decode cookie', {
                originalCookieContent: content,
                parsedCookieContent: value
            });
        }
    }

    #getCookieValue(): string | null {
        const convVCookieValue = getCookieValue(this.#cookie_name);

        // window.convert is set by the convert script. We want to check if convert successfully loaded
        // eslint-disable-next-line no-prototype-builtins
        if (window.hasOwnProperty('convert')) {
            return convVCookieValue;
        }

        return this.#processSpTest(convVCookieValue);
    }

    #processSpTest(convVCookieValue: string | null): string | null {
        const spTestValue = getCookieValue('_conv_sptest');
        if (!spTestValue) {
            return convVCookieValue;
        }

        // https://natuurhuisje.atlassian.net/wiki/spaces/TD/pages/2332065811/Convert+A+B+Test+Server+Side+Setup#Development-notes
        const result = ConvertSpTestTransformer.transFormSpTestToConvV(
            spTestValue,
            convVCookieValue
        );
        setCookieValue('_conv_v', encodeURIComponent(result));
        return result;
    }

    #processContent(content: string): string {
        const start = content.indexOf(this.#exp_key) + this.#exp_key.length;
        let end = content.indexOf('*', start);

        if (end === -1) {
            end = content.length;
        }

        let value = content.substring(start, end);
        value = value.replace(/\./g, ':').replace(/-/g, ',');
        return value.replace(/([a-z0-9]+):/g, '"$1":');
    }
}

const convertReporter = new ConvertReporter();

export default convertReporter;
