declare global {
    interface Window {
        // @ts-ignore
        gtag: Gtag.Gtag;
    }
}

export enum TrackingTimeout {
    SEARCH_DELAY = 750,
    DATE_SEARCH_DELAY = 2000
}

export default class GoogleAnalyticsEventBase {
    readonly #maxEventNameLength = 40;

    protected timeout: number | null = null;

    public trackEvent<T = 'A type parameter is required', AT extends T = T>(
        eventName: string,
        eventParams: AT
    ): void;

    public trackEvent(eventName: string): void;

    public trackEvent<T = 'A type parameter is required', AT extends T = T>(
        eventName: string,
        eventParams?: AT
    ): void {
        if (typeof window.gtag === 'undefined' || eventName.length > this.#maxEventNameLength) {
            return;
        }

        if (!eventParams) {
            window.gtag('event', eventName);
            return;
        }

        window.gtag('event', eventName, eventParams);
    }

    public trackEventWithDelay<T = 'A type parameter is required', AT extends T = T>(
        eventName: string,
        eventParams: AT
    ): void;

    public trackEventWithDelay(eventName: string): void;

    public trackEventWithDelay<T = 'A type parameter is required', AT extends T = T>(
        eventName: string,
        eventParams?: AT,
        delay = 750
    ): void {
        if (this.timeout !== null) {
            window.clearTimeout(this.timeout);
        }

        this.timeout = window.setTimeout(() => {
            if (!eventParams) {
                this.trackEvent(eventName);
                return;
            }

            this.trackEvent<AT>(eventName, eventParams);
        }, delay);
    }

    protected trackConsent(eventName: string, data: JSONObject): void {
        if (typeof window.gtag === 'undefined') {
            return;
        }
        window.gtag('consent', eventName, data);
    }
}
